<template>
  <div id="reservation">
    <!--navigation></navigation-->
    <div class="container reservation">
      <div class="row">
        <div v-if="!isLoggedIn">
          <div class="col s12 m12">
            <ReservationAuth @onSelected="setAuthResult" @onChangedButton="initOnChangedButton"></ReservationAuth>
            <div v-if="login_first" class="modal-trigger" data-target="modal-login">
              <h5 class="auth-first transparent z-depth-0 grey darken-3 orange-text text-lighten-2 waves-effect waves-black">{{ $t('room.to_login')}} <i class="fas fa-angle-double-right"></i></h5>
            </div>
            <div v-if="register_first" class="modal-trigger" data-target="modal-register">
              <h5 class="auth-first transparent z-depth-0 grey darken-3 orange-text waves-effect waves-black">{{ $t('room.to_register') }} <i class="fas fa-angle-double-right"></i></h5>
            </div>
          </div>
          <!-- these two are modal targeted components, the code above go in only one of them -->
          <register></register>
          <login></login>
        </div>
        <!-- Payment Method -->
        <div id="paymentMethod" v-if="isLoggedIn">
          <h4 class="grey-text text-lighten-1 flow-text center">Reservation</h4>
          <payment-method :room="room"></payment-method>
        </div>
      </div>
    </div>
    <div class="footer-section">
      <Footer></Footer>
    </div>
    <div class="fixed-action-btn">
      <a href="#" class="btn-floating orange lighten-2 btn-large tooltipped" data-position="left" data-tooltip="Navigation" Style="opacity:0.7">
        <i class="material-icons">navigation</i>
      </a>
    </div>
  </div>
</template>

<script>
import ReservationAuth from './partials/ReservationAuth'
import Register from '@/components/auth/Register'
import Login from '@/components/auth/Login'
import homeMixin from '@/mixins/homeMixin'
import roomMixin from '@/mixins/roomMixin'
import loggedInMixin from '@/mixins/loggedInMixin'
import PaymentMethod from './payments/PaymentMethod'
import Footer from '@/components/home/Footer'

export default {
  name: 'Reservation',
  data () {
    return {
      login_first: false,
      register_first: false,
      name: '',
      surname: '',
      username: '',
      email: '',
      password: '',
      password_confirmation: '',
      errors: []
    }
  },

  components: {
    ReservationAuth,
    Register,
    Login,
    PaymentMethod,
    Footer
  },

  methods: {
    async registerUser () {
      const user = new URLSearchParams()

      user.append('name', this.name)
      user.append('surname', this.surname)
      user.append('username', this.name + ' ' + this.surname)
      user.append('email', this.email)
      user.append('password', this.password)
      user.append('password_confirmation', this.password_confirmation)

      await this.$store.dispatch('auth/registerUser', user).then(() => {
        // login after registration
        this.loginUser()
      })
    },
    async loginUser () {
      const user = new URLSearchParams()

      user.append('login', this.email)
      user.append('password', this.password)

      await this.$store.dispatch('auth/loginUser', user).then(() => {
        console.log(this.isLoggedIn + ' ... ')
      })
    },
    initOnChangedButton (result) {
      if (result === 'none') {
        this.login_first = false
        this.register_first = false
      }
    },

    setAuthResult (result) {
      if (result === 'yes') {
        this.login_first = true
        this.register_first = false
      }
      if (result === 'no') {
        this.login_first = false
        this.register_first = true
      }
    }
  },
  mounted () {
    window.scrollTo(0, 0)
    const $ = window.jQuery
    $('ul.tabs').tabs()
  },
  mixins: [homeMixin, roomMixin, loggedInMixin]
}
</script>

<style scoped>
/*
  *, ::after, ::before {
    box-sizing: border-box;
  }
 */
  .auth-first {
    cursor: pointer;
    padding: 5px;
    border: solid 1px #0a0a0a;
    border-radius: 5px;
  }
  .footer-section {
    margin-top: 5%;
  }
  #paymentMethod{
    margin-top: 30px;
  }
</style>
