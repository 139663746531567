<template>
  <div id="payment-method">
    <h4 class="grey-text text-lighten-1 flow-text">{{ $t('payment.method') }}</h4>
    <p>
      <label for="paypal">
        <input id="paypal" name="payment-option" type="radio" value="paypal" v-model="payment_method"/>
        <span>{{ $t('payment.with_paypal') }}</span>
      </label>
    </p>
    <div v-if="payment_method === 'paypal'">
      {{ $t('payment.wait_paypal_button') }}
    </div>
    <p>
      <label>
        <input name="payment-option" type="radio" disabled="disabled" value="prepayment" v-model="payment_method"/>
        <span>{{ $t('payment.pre') }} ({{ $t('payment.under_dev') }})</span>
      </label>
    </p>
    <p>
      <label>
        <input name="payment-option" type="radio" disabled="disabled"  value="invoice" v-model="payment_method"/>
        <span>{{ $t('payment.invoice') }} ({{ $t('payment.under_dev') }})</span><br><span v-if="payment_method === 'invoice'">Only for registered companies and organizations</span>
      </label>
    </p>
    <!-- Router Link but not for PayPal -->
    <div v-if="payment_method === 'paypal'">
      <router-link :to="{ name: 'paypal' }" class="btn">{{ $t('payment.continue') }}</router-link>
    </div>
    <div v-if="payment_method === 'prepayment'">
      <router-link :to="{ name: 'prepayment' }" class="btn">{{ $t('payment.continue') }}</router-link>
    </div>
    <div v-if="payment_method === 'cc'">
      <router-link :to="{ name: 'cc' }" class="btn">{{ $t('payment.continue') }}</router-link>
    </div>
    <div v-if="payment_method === 'invoice'">
      <router-link :to="{ name: 'invoice' }" class="btn">{{ $t('payment.continue') }}</router-link>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PaymentMethod',
  components: {
    //
  },

  data () {
    return {
      payment_method: ''
    }
  },

  props: {
    room: {
      type: Object
    }
  },

  mounted () {
    // console.log('in Payment Method: ' + this.room.user_id)
    this.$store.dispatch('rooms/setToken', this.$store.getters['auth/token'])
    this.$store.dispatch('auth/getHirer', this.room.user_id)
    this.$store.dispatch('auth/getHirerProfile', this.room.user_id)
  },

  watch: {
    payment_method: function (val) {
      // console.log('Payment Method: ', val)
    }
  }
}
</script>

<style scoped>
[type="radio"]:checked + span:after, [type="radio"].with-gap:checked + span:after {
  background-color: #ffcc80;
}

[type="radio"]:checked + span:after, [type="radio"].with-gap:checked + span:before,
[type="radio"].with-gap:checked + span:after {
  border: 2px solid #ffa726;
}

</style>
