<template>
  <div>
    <h4 class="grey-text text-lighten-1 flow-text center">{{ $t('room.login_register') }}</h4>
    <p>{{ $t('room.registered_')}}</p>
    <p>
      <label for="login-reserve">
        <input id="login-reserve" @input="checkedInput" ref="yes" name="group1" type="radio"/>
        <span>{{ $t('room.yes')}}</span>
      </label>
    </p>
    <p>
      <label for="register-reserve">
        <input id="register-reserve" @input="checkedInput" ref="no" name="group1" type="radio"/>
        <span>{{ $t('room.no')}}</span>
      </label>
    </p>
    <button class="btn grey darken-2 z-depth-0" @click="emitOnSelected(auth_result)" v-if="!hideContinue">
      {{ $t('continue') }}
    </button>
    <span class="transparent-text">&nbsp;&nbsp;</span>
    <a id="close-register" class="btn grey z-depth-0" @click="goToHome">
      <i class="fa fa-lock"></i> {{ $t('close') }}
    </a>
  </div>
</template>

<script>
import homeMixin from '@/mixins/homeMixin'

export default {
  name: 'ReservationAuth',

  data () {
    return {
      auth_result: '',
      hideContinue: false
    }
  },

  methods: {
    emitOnChangedButton () {
      this.$emit('onChangedButton', 'none')
    },

    checkedInput () {
      // init to none
      this.hideContinue = false
      this.emitOnChangedButton()
      // which radio is checked in aut_result
      if (this.$refs.yes.checked) {
        this.auth_result = 'yes'
      }
      if (this.$refs.no.checked) {
        this.auth_result = 'no'
      }
    },

    emitOnSelected (result) {
      this.hideContinue = true
      this.$emit('onSelected', result)
    }
  },

  mixins: [homeMixin]
}
</script>

<style scoped>

</style>
